import BasicAccordion from 'shared/components/BasicAccordion';
import LinkBox from 'shared/components/LinkBox';
import SectionTitle from 'shared/components/SectionTitle';

function Programa() {
  return (
    <section className="">
      <SectionTitle title="Programa" />

      {/* OBJECTIUS PROGRAMÀTICS */}
      <div className="flex flex-col gap-4 mb-8">
        <h2 className="mb-2 font-black">OBJECTIUS PROGRAMÀTICS</h2>

        <div className="flex flex-col gap-4 border-l-2 border-solid border-gray-futur pl-4">
          <div className="">
            <p>
              Estructurem els objectius pels propers quatre anys en les diverses grans àrees d’acció
              de l’entitat.
            </p>
          </div>

          <BasicAccordion title="Conscienciació lingüística">
            <p>
              El català, al centre significa que ha de ser al centre de la nostra acció com a
              individus: com a espectadors, com a consumidors, com a usuaris, com a ciutadans. Les
              nostres accions quotidianes han de tenir en compte l'impacte lingüístic que generem
              sobre empreses i altres ciutadans.
              <br />
              <br />
              Tot i que les administracions públiques tenen gran part de la responsabilitat de la
              normalització del català, no s’hi pot descarregar tota la responsabilitat. La
              conscienciació de la ciutadania és absolutament clau per contribuir a aquest objectiu.
              Per això hem promogut i continuarem promovent eines que cada any tenen un fort impacte
              públic, com és l'InformeCat, que situa anualment la salut del català al centre del
              debat amb diverses de les dades més importants sobre la vitalitat de la llengua. Això
              ens ajuda a reforçar el missatge de no canviar de llengua, impulsant iniciatives com
              el "No t'excusis! Tots som referents lingüístics".
              <br />
              <br />
              Continuarem impulsant iniciatives per fer que la ciutadania prengui consciència que té
              un paper cabdal en el seu dia a dia per normalitzar el català, i proposarem actuacions
              de conscienciació segmentades per perfils generacionals diversos. En conclusió, cal
              discriminar positivament les accions respectuoses amb la nostra llengua i negativament
              les que la menyspreïn.
              <br />
              <br />A les Illes Balears i el País Valencià durem a terme un pla integral de
              conscienciació i activisme per dotar els ciutadans, socis i activistes de recursos
              davant el nou escenari advers a la llengua dels governs del PP amb el suport de Vox.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Empresa">
            <p>
              La conscienciació lingüística -juntament amb la millora de la legislació i la seva
              correcta aplicació- ha de servir per modular els hàbits de consum del ciutadà i fer
              virar la política lingüística de les empreses. Hem promogut l'acció directa en
              empreses; per exemple, en cadenes de restauració, que han acabat canviant la seva
              política lingüística per fer-la respectuosa amb el català. El tipus d’iniciatives
              d’acció directa que hem fet i continuarem fent inclou, per exemple, denunciar i
              prestar assessorament legal a la treballadora que va ser acomiadada de Veritas per
              parlar en català, i que han suposat que l'empresa canviï la seva política
              d'etiquetatge en favor de la nostra llengua. Potenciarem la gestió de denúncies de
              situacions com aquesta, reforçant la via dual consistent en l'assessorament legal i la
              mediació amb les empreses, per tal que les seves polítiques no discriminin la nostra
              llengua.
              <br />
              <br />
              L’àmbit digital ha de ser un dels focus d’acció dels propers anys. La constitució de
              l’Aliança digital pel català –de la qual Plataforma per la Llengua en forma part– per
              abordar temes com els de la indexació de Google, demostra la importància de fer un
              seguiment estret a les grans empreses digitals, no només per actuar quan sigui
              necessari sinó per crear sinergies amb altres corporacions capdavanteres en innovació
              –especialment, ara, la Intel·ligència Artificial– i, garantir, així, que el català és
              present des del primer dia en les innovacions d’aquest sector. Recentment, en aquest
              sentit, hem estat presents al Mobile World Congress de Barcelona per explicar i fer
              entendre el nostre mercat a les empreses expositores.
              <br />
              <br />
              En paral·lel, seguirem adreçant-nos a empreses, recollint queixes i denunciant
              aquelles societats que no compleixin amb la Llei de política lingüística. Seguirem
              premiant públicament aquelles que siguin lingüísticament responsables. Situar el
              català al centre en l'àmbit empresarial significa que l'entitat faci de lobby davant
              d'aquestes entitats perquè entenguin que el català i que l'entitat han de ser el seu
              aliat, no el seu enemic. Al llarg dels propers mesos farem pública una nova eina
              dirigida a les empreses que serveix per incentivar la seva plena transició cap a la
              normalització lingüística.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Internacional">
            <p>
              L'àmbit internacional de l'entitat ha tingut un paper cabdal a situar el català al
              centre de l’acció pública els darrers mesos. Hem promogut una campanya perquè el
              català esdevingui la 25a llengua oficial de la UE que ha fet palesos els inconvenients
              de no comptar amb aquest reconeixement.
              <br />
              <br />
              Cal continuar demanant al govern espanyol que negocïi per aconseguir-ho. Davant
              d'excuses de tipus jurídic, tenim clares les vies per les quals cal seguir defensant
              l'oficialitat del català a la UE.
              <br />
              <br />
              Paral·lelament a l'oficialitat, aquest 2024 farem aportacions a l’informe del nou
              relator de minories a les Nacions Unides sobre la situació del català a l’Estat
              espanyol (Universal Periodic Review of Spain), i insistirem en el compliment de la
              Carta de Llengües Regionals i Minoritàries que han subscrit els estats espanyol,
              francès i italià. Seguirem reclamant al president francès que compleixi amb la seva
              promesa de ratificar-la, així com que també ho faci l'estat italià. Continuarem
              pressionant perquè l'Estat espanyol compleixi la Carta europea de les llengües
              regionals o minoritàries que va subscriure totalment, i molt especialment en allò que
              fa referència a la justícia, on el dèficit d'ús del català és flagrant.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Administracions públiques">
            <p>
              Des de l’entitat reclamem que totes les administracions aprofitin tot el seu marge
              competencial per dur a terme polítiques en favor de la normalització lingüística. Des
              de l'entitat hem estat punta de llança a denunciar la persecució de la llengua per
              part del govern valencià. Hem posat aquesta situació deplorable al centre de l'agenda,
              i hem mobilitzat la població amb manifestacions perquè aquestes agressions no quedin
              sense resposta. Hem generat consciència sobre la voluntat de la Generalitat valenciana
              d'aniquilar -diguem-ho clarament-, la llengua al nostre país, així com les del govern
              balear d'acabar fent-lo optatiu en molts àmbits.
              <br />
              <br />
              Recentment, vam situar al centre del debat la situació de la llengua al món sanitari
              del Principat. Per això, batallarem perquè s'apliquin les nostres propostes concretes
              per revertir aquesta situació. A les Illes Balears es farà incidència, també
              especialment, en l’àmbit sanitari.
              <br />
              <br />
              En l’àmbit local, hem treballat amb regidors de diversos partits polítics per
              explicar-los com poden afavorir l’ús de la llengua en l’activitat municipal. A
              Barcelona hem esperonat la creació del Pacte Nacional per la llengua –on resulta
              especialment necessari– a més a més d’impulsar-lo en d’altres municipis. Continuarem
              fent seguiment del compliment de les clàusules lingüístiques en les contractacions
              públiques en municipis del País Valencià, les Illes Balears i el Principat de
              Catalunya.
              <br />
              <br />
              Cal ser especialment exigents amb les administracions perquè facin complir la
              normativa que protegeix la nostra llengua i la millorin quan calgui, recorrent als
              tribunals si resulta necessari. Com a poders públics, el seu grau de responsabilitat
              és especialment alt. Aquest equip promourà que l’entitat cooperi amb les
              administracions que tinguin la voluntat d’exigir-lo i fer-lo necessari, sense
              complexos, especialment a l’hora de reforçar els requisits de coneixement de català
              per treballar a l’àmbit públic, i utilitzarà tots els seus mitjans a l’abast per
              plantar cara a aquelles administracions que tinguin la voluntat de fer-lo recular, i
              fins i tot desaparèixer.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Educació">
            <p>
              Aquesta Executiva ha estat punta de llança del teixit associatiu en les iniciatives
              per defensar l’educació plenament en català. Gràcies a això vam aconseguir que no es
              modifiqués la Llei de Política Lingüística catalana fa dos anys, arran de la sentència
              del 25%. Ens comprometem a promoure un debat seriós un cop sigui definitiu l’escenari
              que encari el país els propers anys. A les Illes Balears, vam mobilitzar-nos i
              finalment el castellà no va ser incoporat com a llengua vehicular a la Llei
              d’Educació.
              <br />
              <br />
              Cal que l'entitat lideri el debat públic, posi l'atzucac del català a l'escola al
              centre del debat, i sigui punt de referència per decidir ha de ser els propers anys,
              defugint autoenganys i sense confondre la realitat amb els desitjos. Cal romandre
              atents a la sentència que arribarà sobre la immersió al TSJC i, acte seguit, analitzar
              sense apriorismes quina és la millor manera de garantir que els alumnes del país
              puguin ser escolaritzats en català i se'l sentin seu.
              <br />
              <br />
              La immersió en català al Principat està assetjada per l’aparell judicial espanyol i no
              s’ha aplicat al llarg de les darreres dècades en molts llocs del país, on cada cop hi
              ha menys alumnat i professorat amb el català com a llengua primera (L1).
              <br />
              <br />
              Al País Valencià i a les Illes combatrem, com en d’altres àmbits, les iniciatives per
              fer el català del prescindible a l’ensenyament. Ho farem amb el consens i participació
              el màxim d’entitats i ciutadans com sigui possible.
              <br />
              <br />
              Arreu cal garantir professionals de l’ensenyament també competents en llengua catalana
              a la FP, i ho hem denunciat públicament a les Illes Balears i a Catalunya juntament
              amb el sindicat UGT.
              <br />
              <br />
              A l’Alguer continuarem pressionant perquè l’ensenyament en alguerès sigui una
              realitat.
              <br />
              <br />A les universitats hem fet que els òrgans de govern d'aquestes institucions no
              puguin defugir el tema lingüístic. Hem posat al centre els drets lingüístics de la
              comunitat educativa habilitant una eina anònima que permetrà a estudiants i professors
              queixar-se quan s'incompleix la llengua estipulada d'una matèria d'acord amb el
              procediment de cada universitat. Seguirem de prop la implementació d’aquesta nova
              eina, que ens donarà més informació per denunciar casos de vulneració de drets
              lingüístics.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Audiovisual i cultura">
            <p>
              Durant el 2021 i el 2022 vam posar el català al centre arran de la reforma de la llei
              audiovisual de l'Estat espanyol, pressionant perquè incloguessin quotes de català a
              les plataformes. Fruït d'aquesta acció intensa, es van incorporar algunes quotes
              lingüístiques a la llei i les plataformes han reaccionat, al fet que hi situéssim el
              focus mediàtic, incrementant de manera significativa la seva oferta en català.
              <br />
              <br />
              Però la feina no s'acaba aquí. Cal fer seguiment del compliment de les clàusules
              lingüístiques de la llei espanyola -reclamant, eventualment, una nova revisió de la
              llei-, abordar la futura reforma de la llei audiovisual de Catalunya per introduir-hi
              més garanties lingüístiques i garantir per llei vies de suport als creadors en català,
              seguir l'avaluació de la Directiva europea de l'audiovisual, i potenciar les eines que
              donin visibilitat als continguts en català que es vagin incorporant arreu. Aquest
              darrer punt adquirirà importància a mesura que s’incrementin els continguts en català.
              <br />
              <br />
              Continuarem insistint, així mateix, en les línies estratègiques del Decàleg de
              l'Audiovisual que vam impulsar el 2021, i que incloïa la necessitat que la CCMA
              impulsés formats per joves que, entre d'altres coses, potenciés i aprofités els
              sectors que passen per un moment dolç com el de la creació musical en català. Ara cal
              seguir insistint per la creació de més ficció en català, no només per a joves sinó
              també per a adolescents i preadolescents. Alhora, insistirem en el compliment de la
              Llei del cinema de Catalunya que la Generalitat de Catalunya no ha desplegat mai.
              <br />
              <br />
              Pel què fa als creadors, insistirem en què cal donar visibilitat als que creen
              contingut en català.
              <br />
              <br />
              En l'àmbit musical, cal aprofitar el moment d'or de la música en català, especialment
              de gènere urbà, donant visibilitat als creadors en aquest àmbit tant en els festivals
              que tenen lloc a casa nostra com en espais de ràdio convencional (també a través de la
              llei de l'audiovisual) i, juntament amb el fet d'insistir per donar visibilitat als
              creadors de contingut en català, crear un ecosistema propi, diferenciat i referencial
              en la nostra llengua.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Oci i juventut">
            <p>
              Hem situat el català al centre del món del 'gaming' amb el SAGA, el festival del
              videojoc en català. La segona edició del festival, a la Farga de L'Hospitalet, va
              comportar duplicar l'espai del festival respecte la primera edició i passar de 50 a 75
              punts d'exhibició.
              <br /> <br />
              L’organització del saló ens permet treballar tot l’any amb empreses del sector i
              difondre entre els més joves la presència del català en els videojocs, treballant
              també amb escoles del Baix Llobregat i d’altres punts del domini, per exemple la
              Catalunya Nord. El món del videojoc és un dels focus actuals més massius de l'oci dels
              joves i per això el català no hi pot ser aliè. Seguirem en aquest mateixa direcció,
              fent créixer el festival i promovent premis de joc en català per trencar amb la
              inèrcia històrica de discriminació que durant tants anys ha fet que la nostra llengua
              resulti anòmala en un sector que resulta cabdal per la normalització.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Justícia">
            <p>
              Al llarg dels darrers mesos, l'entitat ha posat el català al centre dels plans
              d'estudis de diverses universitats perquè els drets lingüístics siguin inclosos com a
              matèria als graus de dret de les universitats, i fomentar així la creació d’experts en
              drets lingüístics al nostre domini. Així, a la Universitat de Girona ja s'hi ha inclòs
              una assignatura en aquest sentit, i treballarem amb altres universitats per
              impulsar-hi aquesta mateixa iniciativa.
              <br />
              Alhora, al llarg dels propers mesos col·laborarem amb els Col·legis d'Advocats per
              difondre el nou servei actualitzat de recursos jurídics per treballar en català al món
              de la justícia, i continuarem treballant també amb els notaris.
            </p>
          </BasicAccordion>

          <BasicAccordion title="Arrelament">
            <p>
              Continuarem impulsant el creixement dels tallers en escoles de zones on el català hi
              té una situació especialment dèbil per potenciar l'ús del català en aquests barris. A
              través dels plans de barri, una iniciativa que introdueix el català en barris on el
              català hi té poca presència, treballem amb escoles i instituts, associacions de veïns
              i comerciants i la resta del teixit associatiu del barri la introducció del català com
              a eina de comunicació, cohesió i oportunitats que els permeti, a més a més, conèixer
              millor el país (a través de grups de conversa, tallers de rap i glosa, concerts de
              música en català...). Actualment, tenim en funcionament plans de barri a Barcelona
              (Ciutat Meridiana), Sabadell i Eix Besòs (Sant Adrià-Badalona).
            </p>
          </BasicAccordion>
        </div>
      </div>

      {/* OBJECTIUS OPERATIUS */}
      <div className="">
        <h2 className="mb-4 font-black">OBJECTIUS OPERATIUS</h2>
        <ul className="border-l-2 border-solid border-gray-futur pl-4">
          <li>
            - Superar els 30.000 socis i aconseguir una major incorporació a l’entitat de les
            generacions més joves.
          </li>
          <li>
            - Establir mètodes de participació a l'entitat, especialment pels joves de fins a 25
            anys, a efectes d'incrementar les vies de participació que tenen amb l'entitat.
          </li>
          <li>
            - Dur a terme el desplegament territorial a la Franja i continuar amb el desplegament a
            Andorra. Reforçar les estructures al País Valencià i a les Illes Balears.
          </li>
          <li>
            - Elaborar un pla estratègic pels propers quatre anys que plantegi les millores
            organitzatives que la nova dimensió de l’entitat exigeix i faci possible establir un
            sistema de millora contínua de l'entitat.
          </li>
          <li>
            - Millorar la interfície digital de l'entitat per millorar la participació qualsevol
            soci, tant en assumptes interns de l'entitat, com també quant a la formació en drets
            lingüístics i la gestió d'accions col·lectives i de reforç del voluntariat.
          </li>
          <li>- Reforçar els incentius professionals i la retenció de talent a l’entitat.</li>
          <li>
            -Assolir les millores organitzatives internes que permetin un funcionament encara millor
            de l'entitat.
          </li>
        </ul>
      </div>

      <div className="grid grid-cols-2 gap-2 mt-12">
        <LinkBox title="Presentació" route="/presentacio" />
        <LinkBox title="Candidats" route="/candidats" />
      </div>
    </section>
  );
}

export default Programa;
