import { Link } from 'react-router-dom';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

function LinkBox({ title, description, route, backgroundColor }) {
  return (
    <Link
      to={route}
      className="flex flex-col gap-4 bg-gray-futur p-4 rounded-sm shadow-sm border-l-2 border-solid border-orange-futur hover:bg-gradient-to-r from-orange-futur to-red-futur"
    >
      <div className="flex gap-2 items-center">
        <h2 className="text-xl font-black">{title}</h2>
      </div>

      <div className="flex">
        <h3>Ves-hi</h3>
        <TrendingFlatIcon />
      </div>
    </Link>
  );
}

export default LinkBox;
