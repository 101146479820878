import Programa from 'modules/Programa';
import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const Layout = lazy(() => import('modules/Layout'));
const Home = lazy(() => import('modules/Home'));
const Presentacio = lazy(() => import('modules/Presentacio'));
const Candidats = lazy(() => import('modules/Candidats'));

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);
  return (
    <Suspense fallback="Carregant...">
      <Layout>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/programa" element={<Programa />} />
          <Route path="/presentacio" element={<Presentacio />} />
          <Route path="/candidats" element={<Candidats />} />
        </Routes>
      </Layout>
    </Suspense>
  );
}

export default App;
